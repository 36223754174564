import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import SmartlinkBenefits from '../../components/SmartLink/SmartlinkBenefitCards/SmartlinkBenefits'
import SmartlinkHero from '../../components/SmartLink/SmartlinkHero/SmartlinkHero'
import SmartlinkIdeal from '../../components/SmartLink/SmartlinkIdeal/SmartlinkIdeal'
import { LocaleTypes } from '../../data/locales';
import PanelAnimated from '../../components/PanelAnimated/PanelAnimated';
import SmartlinkScalability from '../../components/SmartLink/SmartlinkScalability/SmartlinkScalability';
import SmartlinkRocket from '../../components/SmartLink/SmartlinkRocket/SmartlinkRocket';

interface SmartlinksPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const SmartlinksPage: React.FC<SmartlinksPageProps> = ({ pathContext, location }) => {


  const content = () => (
    <>
      <SEO title="El Link de pago para realizar cobros de manera ágil y segura | Kushki" description="" />
      <SmartlinkHero/>
      <SmartlinkBenefits  />
      <SmartlinkIdeal />
      <SmartlinkScalability/>
      <PanelAnimated panelName='sm'/>
      <SmartlinkRocket />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default SmartlinksPage;