import React from 'react'
import { FormattedMessage } from 'react-intl'

import './styles.scss'
import video from './assets/videos/smartlink-ideal.mp4';
import check from './assets/images/check-circle.png'
import database from './assets/images/database.png'
import edit from './assets/images/edit-2.png'
import shield from './assets/images/shield.png'

const SmartlinkIdeal: React.FC = () => {
  
  return (
    <>
    <div className="ideal">
      <div className="container">
        <div className="row">
          <div className="ideal--header">
            <h1 className="ideal--header--title">
            <FormattedMessage id="smartlinks.ideal.title" />
            </h1>
            <p className="ideal--header-description">
            <FormattedMessage id="smartlinks.ideal.subtitle" />
            </p>
          </div>
          <section className="smartlink--ideal">
            <div className="ideal--content">
              <div className="ideal--benefits">
                <div className="ideal--benefits--item">
                  <div className="ideal--benefits--item--icon">
                    <img src={check} alt="check" />
                  </div>
                  <div className="ideal--benefits--item--text">
                  <h4 className="ideal--benefits--item--title"><FormattedMessage id="smartlinks.ideal.ideal_1.title" /></h4>
                  <p className="ideal--benefits--item--description"><FormattedMessage id="smartlinks.ideal.ideal_1.content" /></p>
                  </div>
                </div>
                <div className="ideal--benefits--item">
                  <div className="ideal--benefits--item--icon">
                    <img src={edit} alt="edit" />
                  </div>
                  <div className="ideal--benefits--item--text">
                  <h4 className="ideal--benefits--item--title"><FormattedMessage id="smartlinks.ideal.ideal_2.title" /></h4>
                  <p className="ideal--benefits--item--description"><FormattedMessage id="smartlinks.ideal.ideal_2.content" /></p>
                  </div>
                </div>
                <div className="ideal--benefits--item">
                  <div className="ideal--benefits--item--icon">
                    <img src={database} alt="database" />
                  </div>
                  <div className="ideal--benefits--item--text">
                  <h4 className="ideal--benefits--item--title"><FormattedMessage id="smartlinks.ideal.ideal_3.title" /></h4>
                  <p className="ideal--benefits--item--description"><FormattedMessage id="smartlinks.ideal.ideal_3.content" /></p>
                  </div>
                </div>
                <div className="ideal--benefits--item">
                  <div className="ideal--benefits--item--icon">
                    <img src={shield} alt="shield" />
                  </div>
                  <div className="ideal--benefits--item--text">
                  <h4 className="ideal--benefits--item--title"><FormattedMessage id="smartlinks.ideal.ideal_4.title" /></h4>
                  <p className="ideal--benefits--item--description"><FormattedMessage id="smartlinks.ideal.ideal_4.content" /></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video col-lg-6">
              <video loop autoPlay muted className='smartlink-hero-video'> 
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </section>
        </div>
      </div>
    </div>
    </>
  )
}

export default SmartlinkIdeal