import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import CTAButton from '../../CTAButton/CTAButton'

import RocketImg from './assets/images/cohete.svg'

import './styles.scss'

const SmartlinkRocket: React.FC = ({children}) => {
    return (
        <section className="position-relative d-flex justify-content-center">
            <div className="bg-middle-blue"/>
            <div className="rocket-cta-section text-light mx-layout-4 mx-lg-0">
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-5 text-center order-2 order-lg-1">
                            <img className="img-rocket mt-6" src={RocketImg} alt=""/>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-center align-items-center order-1 order-lg-2">
                            <div>
                                <h2 className="mt-layout-4 mt-lg-0 text-primary">
                                    <FormattedMessage id="smartlinks.rocket.title" values={defaultFormatValues} />
                                </h2>
                                <CTAButton>
                                    <FormattedMessage id="buttons.sales"/>
                                </CTAButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SmartlinkRocket