import React from 'react'
import { FormattedMessage } from 'react-intl';

import './styles.scss'

import green from './assets/images/yellow.png'
import blue from './assets/images/blue.png'
import pink from './assets/images/pink.png'
import yellow from './assets/images/yellow-1.png'

const SmartlinkBenefits: React.FC = () => {
        return (
          <>
            <section className="container mb-7">
              <h1 className="smartlink-benefits-title">
                <FormattedMessage id="smartlinks.benefits.title"/>
              </h1>
              <div className="row">
                <div className="col-md-6 col-sm-12 order-1">
                  <div className="card card-benefit-space">
                    <div className="card-body">
                      <img src={green} className="benefits-card-img mt-sm-card"/>
                      <h4 className="benefits-card-title text-primary"><FormattedMessage id="smartlinks.benefits.benefit_1.title" /></h4>
                      <p className="lead"><FormattedMessage id="smartlinks.benefits.benefit_1.subtitle" /></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 order-2 mt-sm-card mt-md-0">
                  <div className="card card-benefit-space">
                    <div className="card-body">
                      <img src={blue} className="benefits-card-img"/>
                      <h4 className="benefits-card-title text-primary"><FormattedMessage id="smartlinks.benefits.benefit_2.title" /></h4>
                      <p className="lead"><FormattedMessage id="smartlinks.benefits.benefit_2.subtitle" /></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-md-3">
                <div className="col-md-6 col-sm-12 order-1 mt-sm-card">
                  <div className="card card-benefit-space">
                    <div className="card-body">
                      <img src={pink} className="benefits-card-img"/>
                      <h4 className="benefits-card-title text-primary"><FormattedMessage id="smartlinks.benefits.benefit_3.title" /></h4>
                      <p className="lead"><FormattedMessage id="smartlinks.benefits.benefit_3.subtitle" /></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 order-2 mt-sm-card">
                  <div className="card card-benefit-space">
                    <div className="card-body">
                      <img src={yellow} className="benefits-card-img"/>
                      <h4 className="benefits-card-title text-primary"><FormattedMessage id="smartlinks.benefits.benefit_4.title" /></h4>
                      <p className="lead"><FormattedMessage id="smartlinks.benefits.benefit_4.subtitle" /></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )
    }

export default SmartlinkBenefits