import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import AccordionSection from '../../Accordion/AccordionSection'

import callImg from './assets/images/call.svg'
import phoneImg from './assets/images/phone.svg'
import shopImg from './assets/images/shop.svg'
import workImg from './assets/images/work.svg'

import './styles.scss'

const SmartlinkScalability: React.FC = () => {

    const [isOpen1, setIsOpen1] = useState(true)
    const [isOpen2, setIsOpen2] = useState(false)
    const [isOpen3, setIsOpen3] = useState(false)
    const [isOpen4, setIsOpen4] = useState(false)

    const [stateImage, setStateImage] = useState({
      url: callImg,
      alt: 'call'
    })
    const validateOpen = (elementId: number) => {
      if (elementId === 1) {
        setIsOpen1(true)
        setIsOpen2(false)
        setIsOpen3(false)
        setIsOpen4(false)
        setStateImage({
          url: callImg,
          alt: 'call'
        })
      } else if (elementId === 2) {
        setIsOpen1(false)
        setIsOpen2(true)
        setIsOpen3(false)
        setIsOpen4(false)
        setStateImage({
          url: workImg,
          alt: 'working'
        })
      } else if (elementId === 3) {
        setIsOpen1(false)
        setIsOpen2(false)
        setIsOpen3(true)
        setIsOpen4(false)
        setStateImage({
          url: shopImg,
          alt: 'shoping'
        })
      } else {
        setIsOpen1(false)
        setIsOpen2(false)
        setIsOpen3(false)
        setIsOpen4(true)
        setStateImage({
          url: phoneImg,
          alt: 'phone'
        })
      }
    }

  return (
    <section className="container my-layout-3">
      <h1 className='scalability-title'><FormattedMessage id="smartlinks.scalability.title" /></h1>
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="card">
            <AccordionSection event={validateOpen} elementId={1} openProp={isOpen1} title={
              <FormattedMessage id="smartlinks.scalability.card1.title" />
            }>
              <p>
                <FormattedMessage id="smartlinks.scalability.card1.content" />
              </p>
              <img className="d-md-none side-img" src={callImg} alt={stateImage.alt} />
            </AccordionSection>
          </div>
          <div className="card">
            <AccordionSection event={validateOpen} elementId={2} openProp={isOpen2} title={
              <FormattedMessage id="smartlinks.scalability.card2.title" />
            }>
              <p>
                <FormattedMessage id="smartlinks.scalability.card2.content" />
              </p>
              <img className="d-md-none side-img" src={workImg} alt={stateImage.alt} />
            </AccordionSection>
          </div>
          <div className="card">
            <AccordionSection event={validateOpen} elementId={3} openProp={isOpen3} title={
              <FormattedMessage id="smartlinks.scalability.card3.title" />
            }>
              <p>
                <FormattedMessage id="smartlinks.scalability.card3.content" />
              </p>
              <img className="d-md-none side-img" src={shopImg} alt={stateImage.alt} />
            </AccordionSection>
          </div>
          <div className="card">
            <AccordionSection event={validateOpen} elementId={4} openProp={isOpen4} title={
              <FormattedMessage id="smartlinks.scalability.card4.title" />
            }>
              <p>
                <FormattedMessage id="smartlinks.scalability.card4.content" />
              </p>
              <img className="d-md-none side-img" src={phoneImg} alt={stateImage.alt} />
            </AccordionSection>
          </div>
        </div>
        <div className="col-md-6 d-none d-md-flex justify-content-center">
          <img className="side-img" src={stateImage.url} alt={stateImage.alt} />
        </div>
      </div>
    </section>
  )
}

export default SmartlinkScalability