import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'

import video from './assets/hero-smartlink.mp4';
import SalesButton from '../../SalesButton/SalesButton';

const SmartlinkHero: React.FC = (SmartlinkProps) => {

  return (
    <>
      <div className="hero--smartlink--container">
        <div className="container">
          <div className="row smartlink-hero">
            <div className="title-smart col-12 order-2 order-lg-1 col-lg-6 mt-7">
              <h1 className="Smartlink-title mt-7 text-primary"><FormattedMessage id="smartlinks.title" /></h1>
              <p className="lead description-hero"><FormattedMessage id="smartlinks.description" /></p>
              <div className="button-responsive-row mt-2">
                <SalesButton positionTag="top">
                  <FormattedMessage id="buttons.sales"/>
                </SalesButton>
              </div>
            </div>
            <div className="video col-lg-6 col-12 order-1 order-lg-2">
              <video loop autoPlay muted className="smartlink-hero-video"> 
                <source  src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmartlinkHero 